import React, { useEffect } from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Video from '../../components/Video'
import { useStaticQuery, graphql } from 'gatsby'
import { SocialMediaLinks } from '../../utils'

const PageVariants = ({ pageContext, location }) => {
    const page = pageContext.page;

    const data = useStaticQuery(graphql`
    query {
        favicon: file(relativePath: {eq: "favicon-w200.png"}) {
            id
            childImageSharp {
                original {
                    src
                }
            }
        }
    }`)

    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href },
            ].concat(SocialMediaLinks(data.favicon?.childImageSharp.original.src))}/>

            <Video 
                pageTitle={`${page.title} Youtube`} 
                videoUrl={`https://www.youtube.com/embed/sEvN01fDhfA?autoplay=1&enablejsapi=1&autohide=0&controls=1&rel=0&showinfo=0&loop=1`}/>

        </Layout>
    )
}

export default PageVariants